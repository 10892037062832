import React, { useState } from 'react';
import '../styles/dropdown.css';
import { HashLink as HLink } from 'react-router-hash-link';
import Menu from './menu';

const Dropdown = () => {
  const [click, setClick] = useState(false);

  // To toggle dropdown menu on and off
  const handleClick = () => {
    setClick(!click);
  };

  return (
    <div>
      <ul
        onClick={handleClick}
        className={click ? 'dropdown-menu clicked' : 'dropdown-menu'}
        onKeyPress={handleClick}
        role="menu"
        tabIndex="0"
      >
        {Menu.map((item) => {
          return (
            <li key={item.id}>
              <HLink
                className={item.cName}
                to={item.path}
                onClick={() => {
                  setClick(false);
                }}
              >
                {item.title}
              </HLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Dropdown;

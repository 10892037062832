import React from 'react';
import '../styles/services.css';

const Hosting = () => {
  return (
    <div className="hosting service-option">
      <div className="content">
        <div className="title">HOSTING</div>
        <div className="info">
          We are able to host the website(s) you already have!
        </div>
      </div>
    </div>
  );
};

export default Hosting;

import React from 'react';
import '../../styles/home.css';

const Home = () => {
  return (
    <div className="page home">
      <div className="welcome-container">
        <div className="welcome-message title-emph">
          <div className="title">Design + Develop</div>
          <div className="message">
            Getting a new website doesn&apos;t have to be tedious. Let us take that load off your
            shoulders!
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

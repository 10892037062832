import React from 'react';
import '../../styles/services.css';
import Hosting from '../hosting';
import Development from '../development';
import Migration from '../migration';
import Consulting from '../consulting';
import TechSupport from '../tech-support';

const Services = () => {
  return (
    <div className="services page">
      <div className="title-container title-emph">
        <div className="services title">Services</div>
        <div className="service-option-links">
          <a
            className="service-option-link"
            href="#hosting"
          >
            Hosting
          </a>
          <a
            className="service-option-link"
            href="#development"
          >
            Development
          </a>
          <a
            className="service-option-link"
            href="#migration"

          >
            Migration
          </a>
          <a
            className="service-option-link"
            href="#consulting"
          >
            Consulting
          </a>
          <a
            className="service-option-link"
            href="#tech-support"
          >
            Tech Support
          </a>
        </div>
      </div>
      <div id="hosting" className="anchor" />
      <Hosting />
      <div id="development" className="anchor" />
      <Development />
      <div id="migration" className="anchor" />
      <Migration />
      <div id="consulting" className="anchor" />
      <Consulting />
      <div id="tech-support" className="anchor" />
      <TechSupport />
    </div>
  );
};

export default Services;

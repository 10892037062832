import React from 'react';
import '../styles/services.css';

const Migration = () => {
  return (
    <div className="migration service-option">
      <div className="content">
        <div className="title">MIGRATION</div>
        <div className="info">
          We are able to transfer all your content to our systems!
        </div>
      </div>
    </div>
  );
};

export default Migration;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './styles/global.css';
import Header from './components/header';
import Footer from './components/footer';
import Home from './components/pages/home';
import Services from './components/pages/services';
import About from './components/pages/about';
import ContactUs from './components/pages/contact';

const App = () => {
  return (
    <div className="body-container">
      <div className="above-footer">
        <Router>
          <Header />
          <div className="pages">
            <Routes>
              <Route path="/" exact element={<Home />} />
              <Route path="/services" exact element={<Services />} />
              <Route path="/about" exact element={<About />} />
              <Route path="/contact" exact element={<ContactUs />} />
            </Routes>
          </div>
        </Router>
      </div>
      <Footer />
    </div>
  );
};

export default App;

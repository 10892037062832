import React from 'react';
import '../styles/services.css';

const Development = () => {
  return (
    <div className="development service-option">
      <div className="content">
        <div className="title">DEVELOPMENT</div>
        <div className="info">
          We are able to design a new website for you!
        </div>
      </div>
    </div>
  );
};

export default Development;

const Menu = [
  {
    id: 0,
    title: 'Hosting',
    path: './services#hosting',
    cName: 'dropdown-link',
  },
  {
    id: 1,
    title: 'Development',
    path: '/services#development',
    cName: 'dropdown-link',
  },
  {
    id: 2,
    title: 'Migration',
    path: '/services#migration',
    cName: 'dropdown-link',
  },
  {
    id: 3,
    title: 'Consulting',
    path: '/services#consulting',
    cName: 'dropdown-link',
  },
  {
    id: 4,
    title: 'Tech Support',
    path: '/services#tech-support',
    cName: 'dropdown-link',
  },
];

export default Menu;

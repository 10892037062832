import React, { useState } from 'react';
import '../styles/header.css';
import { Link } from 'react-router-dom';
import CompanyLogo from './company-logo';
import Dropdown from './dropdown';

const Header = () => {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  // To toggle hamburger menu on and off
  const handleClick = () => {
    setClick(!click);
  };

  const closeMobileMenu = () => {
    setClick(false);
  };

  const onMouseEnter = () => {
    if (window.innerWidth <= 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };
  const onDropClick = () => {
    setDropdown(true);
  };
  const onMouseLeave = () => {
    setDropdown(false);
  };

  return (
    <nav className="container">
      <div className="header">
        <Link to="/" className="logo" onClick={closeMobileMenu}>
          <CompanyLogo />
        </Link>
        <div
          className="header-menu-icon"
          onClick={handleClick}
          onKeyPress={handleClick}
          role="button"
          tabIndex="0"
        >
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
        <ul className={click ? 'header-menu active' : 'header-menu'}>
          <li className="header-item">
            <Link to="/" className="header-link" onClick={closeMobileMenu}>
              HOME
            </Link>
          </li>
          <li
            className="header-item services"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <Link to="/services" className="header-link" onClick={closeMobileMenu}>
              SERVICES
              <i
                className="fas fa-caret-down"
                onClick={onDropClick}
                onKeyPress={onDropClick}
                role="menu"
                tabIndex="0"
              />
            </Link>
            {dropdown && <Dropdown />}
          </li>
          <li className="header-item">
            <Link to="/about" className="header-link" onClick={closeMobileMenu}>
              ABOUT
            </Link>
          </li>
          <li className="header-item">
            <Link to="/contact" className="header-link" onClick={closeMobileMenu}>
              CONTACT US
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Header;

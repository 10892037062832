import React from 'react';
import '../styles/services.css';

const TechSupport = () => {
  return (
    <div className="tech-support service-option">
      <div className="content">
        <div className="title">TECH SUPPORT</div>
        <div className="info">
          Having problems with your system? We&apos;re here to help!
        </div>
      </div>
    </div>
  );
};

export default TechSupport;

import React from 'react';
import '../styles/services.css';

const Consulting = () => {
  return (
    <div className="consulting service-option">
      <div className="content">
        <div className="title">CONSULTING</div>
        <div className="info">
          If you want to design or host a website yourself, we can help!
        </div>
      </div>
    </div>
  );
};

export default Consulting;

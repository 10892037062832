import React from 'react';
import '../styles/company-logo.css';

const CompanyLogo = () => {
  return (
    <div className="logo-container">
      <svg viewBox="85.178 110.231 244.313 59.48" width="244.313" height="59.48">
        <g transform="matrix(1, 0, 0, 1, -63.792229, 37.674484)">
          <title>Tracecms</title>
          <ellipse cx="209.643" cy="87.607" rx="15.216" ry="15.05" />
          <ellipse cx="209.563" cy="87.663" rx="5.091" ry="15.023" />
          <path
            className="path01"
            d="M 195.971 83.233 C 205.162 83.233 214.275 83.068 223.485 83.068"
          />
          <path
            className="path02"
            d="M 195.586 92.01 C 204.924 92.01 214.182 91.845 223.54 91.845"
          />
          <path
            className="path03"
            d="M 197.511 97.981 C 198.408 98.673 193.885 102.672 193.769 103.429 C 194.118 104.421 202.539 102.551 202.464 102.108"
          />
          <path
            className="path04"
            d="M 156.814 81.621 L 190.672 81.621 C 190.033 83.478 189.688 85.457 189.688 87.511 C 189.688 87.573 189.688 87.637 189.689 87.699 L 159.727 87.699 L 159.909 119.247 L 162.79 119.23 C 162.826 119.225 162.863 119.222 162.9 119.222 L 163.58 119.225 L 209.176 118.955 L 209.211 106.736 C 209.579 106.755 209.949 106.764 210.321 106.764 C 212.311 106.764 214.235 106.501 216.056 106.01 L 216.056 119.246 C 216.141 119.318 216.224 119.402 216.308 119.495 L 216.32 119.495 C 216.324 119.504 216.328 119.512 216.331 119.521 C 216.671 119.907 217.008 120.453 217.387 121.081 C 217.853 121.616 218.36 122.121 218.779 122.674 C 219.805 124.026 220.965 125.572 220.965 126.593 L 220.965 128.723 C 220.965 130.538 219.644 132.009 218.014 132.009 L 217.933 132.009 C 217.782 132.028 217.629 132.037 217.473 132.037 L 152.341 132.037 C 150.479 132.037 148.97 130.528 148.97 128.666 L 148.97 126.481 C 148.97 125.434 150.295 123.848 151.467 122.46 C 152.229 121.556 152.851 120.838 153.394 119.881 L 153.394 85.178 C 153.506 84.389 153.918 83.16 154.395 82.663 C 154.873 82.166 156.054 81.738 156.814 81.621 Z M 191.776 125.915 L 177.656 125.915 C 177.653 125.947 177.652 125.979 177.652 126.011 C 177.652 127.6 180.815 128.888 184.716 128.888 C 188.617 128.888 191.78 127.6 191.78 126.011 C 191.78 125.979 191.779 125.947 191.776 125.915 Z"
          />
          <path
            className="path05"
            d="M 192.281 126.154 C 192.281 126.651 191.982 127.205 191.567 127.586 C 191.171 127.951 190.574 128.292 189.904 128.553 C 188.586 129.066 186.737 129.388 184.753 129.388 C 182.769 129.388 180.92 129.066 179.602 128.553 C 178.932 128.292 178.335 127.951 177.939 127.586 C 177.524 127.205 177.225 126.651 177.225 126.154 C 177.225 125.657 177.524 125.103 177.939 124.722 C 178.335 124.357 178.932 124.016 179.602 123.755 C 180.92 123.242 182.769 122.92 184.753 122.92 C 186.737 122.92 188.586 123.242 189.904 123.755 C 190.574 124.016 191.171 124.357 191.567 124.722 C 191.982 125.103 192.281 125.657 192.281 126.154 Z M 190.89 125.458 C 190.575 125.168 190.143 124.921 189.541 124.687 C 188.315 124.21 186.651 123.92 184.753 123.92 C 182.855 123.92 181.191 124.21 179.965 124.687 C 179.363 124.921 178.931 125.168 178.616 125.458 C 178.319 125.731 178.225 125.896 178.225 126.154 C 178.225 126.412 178.319 126.577 178.616 126.85 C 178.931 127.14 179.363 127.387 179.965 127.621 C 181.191 128.098 182.855 128.388 184.753 128.388 C 186.651 128.388 188.315 128.098 189.541 127.621 C 190.143 127.387 190.575 127.14 190.89 126.85 C 191.187 126.577 191.281 126.412 191.281 126.154 C 191.281 125.896 191.187 125.731 190.89 125.458 Z"
          />
        </g>
        <rect x="92.374" y="121.795" width="55.84" height="35.71" rx="0.82" ry="0.82" />
        <text x="174.77" y="157.705">
          tracecms
        </text>
      </svg>
    </div>
  );
};

export default CompanyLogo;

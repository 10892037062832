import React, { useState, useEffect } from 'react';
import '../../styles/contact.css';
import SaveMessage from '../firebase/firebase-message';

const ContactUs = () => {
  // Error message states
  const [nameErrorMessage, setNameErrorMessage] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [questionErrorMessage, setQuestionErrorMessage] = useState('');
  const [phoneErrorMessage, setPhoneErrorMessage] = useState('');

  // Error class states
  const [nameErrorClass, setNameErrorClass] = useState('error');
  const [emailErrorClass, setEmailErrorClass] = useState('error');
  const [questionErrorClass, setQuestionErrorClass] = useState('error');
  const [phoneErrorClass, setPhoneErrorClass] = useState('error');

  const showError = (target) => {
    switch (target.name) {
      case 'name': {
        if (target.validity.valueMissing) {
          setNameErrorMessage('You need to enter your name.');
        } else if (target.validity.tooShort) {
          setNameErrorMessage(
            `Name should be at least ${target.minLength} characters; you entered ${target.value.length}.`
          );
        } else if (target.validity.patternMismatch) {
          setNameErrorMessage('Please enter a valid name (only letters allowed).');
        }
        setNameErrorClass('error active');
        break;
      }
      case 'email': {
        if (target.validity.valueMissing) {
          setEmailErrorMessage('You need to enter an e-mail address.');
        } else if (target.validity.typeMismatch) {
          setEmailErrorMessage('Please enter a valid email. Example: john.doe@gmail.com.');
        } else if (target.validity.tooShort) {
          setEmailErrorMessage(
            `Email should be at least ${target.minLength} characters; you entered ${target.value.length}.`
          );
        }
        setEmailErrorClass('error active');
        break;
      }
      case 'question': {
        if (target.validity.valueMissing) {
          setQuestionErrorMessage('Please tell us more about the service(s) you require.');
        } else if (target.validity.tooShort) {
          setQuestionErrorMessage(
            `Message should be at least ${target.minLength} characters; you entered ${target.value.length}.`
          );
        }
        setQuestionErrorClass('error active');
        break;
      }
      case 'phone': {
        if (target.validity.patternMismatch) {
          setPhoneErrorMessage('Please enter a valid phone number. Example: (123) 456-7890.');
        }
        setPhoneErrorClass('error active');
        break;
      }
      default: {
        throw new Error('Cannot read form input id!');
      }
    }
  };

  const checkInput = (e) => {
    e.preventDefault();
    if (e.target.validity.valid) {
      // Reset states for error messages
      setNameErrorMessage('');
      setEmailErrorMessage('');
      setQuestionErrorMessage('');
      setPhoneErrorMessage('');

      // Reset states for error classes
      setNameErrorClass('error');
      setEmailErrorClass('error');
      setQuestionErrorClass('error');
      setPhoneErrorClass('error');
    } else {
      showError(e.target);
    }
  };

  // Check if form can be submitted
  const canSubmit = () => {
    if (document.querySelectorAll(':invalid')[1]) {
      // Found an entry that was invalid, prevent refresh of the page and point to it
      document.querySelectorAll(':invalid')[1].focus();
      return false;
    }
    return true;
  };

  // Append reCAPTCHA script to page on load
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_SITE_KEY_PROD}`;
    document.body.appendChild(script);
  }, []);

  // Helper function to get form inputs
  const getInputVal = (name) => {
    return document.getElementsByName(name)[0] ? document.getElementsByName(name)[0].value : '';
  };

  // Send data to firebase
  const sendInfo = () => {
    SaveMessage({
      name: getInputVal('name'),
      email: getInputVal('email'),
      question: getInputVal('question'),
      phone: getInputVal('phone'),
      message: getInputVal('message'),
    });
  };

  // Submit form after verifying reCAPTCHA
  const submitForm = (e) => {
    e.preventDefault(); // Prevent page from reloading
    if (!canSubmit()) return false;
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_SITE_KEY_PROD, { action: 'submit' })
        .then((token) => {
          console.info('Sending contact info to db...');
          sendInfo();
        })
        .catch((error) => {
          console.error('Error occurred while handling captcha', error);
        });
    });
    return true;
  };

  return (
    <div className="contact page">
      <div className="contact-section">
        <form id="contact" method="post" onSubmit={submitForm} noValidate>
          <div className="title">Let&apos;s get started!</div>
          <input
            type="text"
            id="name"
            name="name"
            pattern="[A-zÀ-ž -]+"
            minLength="2"
            placeholder="Name"
            onInput={checkInput}
            required
          />
          <span aria-live="polite" className={nameErrorClass}>
            {nameErrorMessage}
          </span>
          <input
            type="email"
            id="email"
            name="email"
            minLength="8"
            placeholder="Email Address"
            onInput={checkInput}
            required
          />
          <span aria-live="polite" className={emailErrorClass}>
            {emailErrorMessage}
          </span>
          <input
            type="text"
            id="question"
            name="question"
            minLength="5"
            placeholder="How can we assist?"
            onInput={checkInput}
            required
          />
          <span aria-live="polite" className={questionErrorClass}>
            {questionErrorMessage}
          </span>
          <input
            type="tel"
            id="phone"
            name="phone"
            pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"
            placeholder="(123) 456-7890"
            onInput={checkInput}
          />
          <span aria-live="polite" className={phoneErrorClass}>
            {phoneErrorMessage}
          </span>
          <textarea
            maxLength="1000"
            name="message"
            id="textarea"
            placeholder="Write us a message here! Give us some more details about what you're looking for."
          />
          <button
            type="submit"
            id="submit"
          >
            Submit
          </button>
          <div
            className="g-recaptcha"
            data-sitekey={process.env.REACT_APP_SITE_KEY_PROD}
            data-size="invisible"
          />
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
